'use strict';

var callBound = require('call-bound');
var safeRegexTest = require('safe-regex-test');
var isFnRegex = safeRegexTest(/^\s*(?:function)?\*/);
var hasToStringTag = require('has-tostringtag/shams')();
var getProto = require('get-proto');
var toStr = callBound('Object.prototype.toString');
var fnToStr = callBound('Function.prototype.toString');
var getGeneratorFunc = function () {
  // eslint-disable-line consistent-return
  if (!hasToStringTag) {
    return false;
  }
  try {
    return Function('return function*() {}')();
  } catch (e) {}
};
/** @type {undefined | false | null | GeneratorFunctionConstructor} */
var GeneratorFunction;

/** @type {import('.')} */
module.exports = function isGeneratorFunction(fn) {
  if (typeof fn !== 'function') {
    return false;
  }
  if (isFnRegex(fnToStr(fn))) {
    return true;
  }
  if (!hasToStringTag) {
    var str = toStr(fn);
    return str === '[object GeneratorFunction]';
  }
  if (!getProto) {
    return false;
  }
  if (typeof GeneratorFunction === 'undefined') {
    var generatorFunc = getGeneratorFunc();
    GeneratorFunction = generatorFunc
    // eslint-disable-next-line no-extra-parens
    ? ( /** @type {GeneratorFunctionConstructor} */getProto(generatorFunc)) : false;
  }
  return getProto(fn) === GeneratorFunction;
};